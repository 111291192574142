import axios from "axios";
import config from '../config';
import {createAuth} from "../Helpers";

export const reducerName = 'pauseState'

const PAUSEVIDEO_GETALL_RESPONSE = 'PAUSEVIDEO_GETALL_RESPONSE';
const NEXT_VIDEO = 'NEXT_VIDEO';

const initialState = {
    currentVideoIndex: 0,
    staticPauseVideo: false,
    pauseVideos: []
}; 

export const actionCreators = {
    getAll: () => async(dispatch, getState) => {
        axios({
            method: 'GET',
            url: `${config.server}/api/audiovideos?group=ALL&tag=PauseScreen`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({type: PAUSEVIDEO_GETALL_RESPONSE, data: response.data});
        }).catch(error =>{
            console.log('ERROR FETCHING PAUSEVIDEOS', error);
        });
    },
    nextVideo: () => async (dispatch, getState) => {
        dispatch({ type: NEXT_VIDEO });
    }
}

const reducerMethods = {
    PAUSEVIDEO_GETALL_RESPONSE: (state, action) => {
        return{
            ...state,
            pauseVideos: action.data
        }
    },
    NEXT_VIDEO: (state) => {
        if(state.currentVideoIndex >= state.pauseVideos.length){
            return {
                ...state,
                currentVideoIndex: 0
            }  
        } 
        else
        {
            return {
                ...state,
                currentVideoIndex: state.currentVideoIndex + 1
            }   
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) {
        return method(state, action);
    }
    return state;
};