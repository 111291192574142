import React from 'react';
import connect from 'react-redux/es/connect/connect';
import './Wind.css';
import windBag from '../../images/cropped_and_rotated_wind_without_white_border.gif';

import noWind from '../../images/QuestionMark.svg';

const Wind = props => {

    const textStyle = {
        fontSize: `${90*(0.02*props.width)}%`,
    }

    const wind = {...props.windState.wind};

    const windPlacement = {
        left: '85.5vw', 
        top: '21vh', 
        width: '6.6vh',
        ...props.windPlacement
    }

    // placeringen af wind er blevet ud fra hvilken device det vises på
    // hvis der ikke er loadet nogen oplysninger ind ift. device, så gives placering ud fra standardværdier som originalt var på ørstedkortet
    let windPlacementStyling = {
        left: windPlacement.left,
        top: windPlacement.top,
        width: windPlacement.width
    }

    // hvis direction er 0 == er det vindstille
    // hvis direction er 360 == er det mod nord
    if(wind.direction === undefined && wind.speed === undefined){

        if(props.windPlacement !== undefined && props.windPlacement.error){
            windPlacementStyling = {
                ...windPlacementStyling,
                ...props.windPlacement.error
            }
        }

        return(
            <div className='wind' style={windPlacementStyling}>
                <div className='wind-bag-wrapper'>
                    <img src={noWind} className='no-wind-bag' alt="icon"></img>
                </div>
                <p className={'speed'} style={textStyle}>Vind ikke tilgængelig</p>
            </div>
        )
    }
    

    if(wind.direction === 0){
        // fordi ellers kunne den være undefined
        wind.speed = 0;
    }

    // TODO hvis det skal være forskelligt fra værk til værk kan man tilføje en prop på wind som hedder noget med degreesToTurn og så kan man tilføje det til directionen
    // fordi den skal drejes 180 grader, så den passer med at kortet vender på hovedet ift. syd og nord
    // 180 fordi animationen peger opad mod nord (men den burde pege mod syd)
    //wind.direction = wind.direction + 180 + 180;

    // hvis pilen peger nord-vest skal teksten ikke stå på hovedet
    // derfor: hvis pilen skal drejes mere end 180 grader
    //      dvs. fra den begynder at dreje sydvest (til og med --> nordvest --> nord) skal teksten dreje den anden vej, så det er læseligt

    wind.direction = wind.direction + 180;
    
    const wrapperStyle = {
        transform: `rotate(${(wind.direction === 180 || wind.direction === undefined) ? 90 : wind.direction + props.degreesToRotate}deg)`,
        width: props.width + '%',
    };

    return(
        <div className='wind' style={windPlacementStyling}>
            <div className='wind-bag-wrapper' style={wrapperStyle}>
                <img src={windBag} className='wind-bag' alt="icon"></img>
            </div>
            <p className={'speed'} style={textStyle}>{wind.speed} m/s</p>
        </div>
    )
};

export default connect(
    state => ({windState: state.windState}) 
)(Wind);
