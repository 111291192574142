import React from 'react';
import SafetyInduction from './SafetyInduction';
import Menu from '../Menu';
import { actionCreators as storyActions } from '../../store/StoryStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useEffect } from 'react';
import './ExtraInteractiveStory.css';


const ExtraInteractiveStory = (props) => {
    const storyActions = props.storyActions;
    const storyState = props.storyState;
    function changeStory(id){
        storyActions.setPlaying(false);
        storyActions.clearChapterActions();
        storyActions.toggleInfoPopup(undefined, false);
        storyActions.changeStoryAction({storyId:id})
   } 

    useEffect(
        () => {
            if (defaultStoryId() !== undefined) {
                let storyId = defaultStoryId()
                changeStory(storyId)
            }
        },
        []
    );

    const defaultStoryId = () => {
        if (storyState.stories[0].chapters.length !== 0) {
            var story = storyState.stories.find(s => s.cultureCode === 'da-DK' && s.category === 'Miljøplakat')
            if (story !== undefined) {
                var storyId = story.id
                return storyId
            }
        }
        return undefined
    };

    if (defaultStoryId() !== undefined) {
        return <>
            <div className="App">
                <Menu/>
                <SafetyInduction 
                    isSafetyInduction={false}
                    languageButtonIsVisible={false}
                />
                <div className='animated flash'/>
            </div>
        </>   
    }
    else
    {
        return <>
            <div className='App'>
                <Menu/>
                Der findes ingen miljø historier. <br/>
                Tryk tilbage til sikkerhedsintroduktionen, ved at klikke på den øverste ikon til venstre.
            </div>
        </>
    }
};

export default connect(
    state => ({
        storyState: state.storyState,
        deviceState: state.deviceState,
    }),
   dispatch => ({
        storyActions: bindActionCreators(storyActions, dispatch)
    })
)(ExtraInteractiveStory);