import React from 'react';
import { actionCreators as instructionActions } from '../../store/InstructionStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './Instructions.css';
import Menu from '../Menu';
import { InstructionItem } from './InstructionItem';
import InstructionPlayer from './InstructionPlayer';
import config from '../../config';


const Instructions = (props) => {
    const instructionState = props.instructionState;
    const instructionActions = props.instructionActions;
    
    var videos = instructionState.instructionVideos

    const play = (index) => {
        instructionActions.play(index)
    }

    return <>
        <div className="App">
            <Menu className='menu instruction'/>
            <div className='instruktions-container'>
                {videos.length !== 0 ? 
                    <div className='row'>
                        {videos.map((video, index) => 
                            <div className="column" key={index} onClick={() => play(index)}>
                                <InstructionItem
                                    path={config.server + video.path}
                                    type={video.type}
                                    name={video.name}
                                />
                            </div>
                        )}
                    </div> :
                    <div>
                        Ingen instruktions videoer fundet
                    </div>
                }
                {
                    instructionState.currentVideo !== null ?
                    <div className='outer-video-container' onClick={() => instructionActions.stopPlay()}>
                        <div className={'InstructionPlayer-container'}>
                            <InstructionPlayer
                                path={config.server + videos[instructionState.currentVideo].path}
                            />
                        </div>
                    </div> :
                    <></>
                }
            </div>
        </div>
    </>     
};

export default connect(
    state => ({
        instructionState: state.instructionState
    }),
   dispatch => ({
        instructionActions: bindActionCreators(instructionActions, dispatch)
    })
)(Instructions);