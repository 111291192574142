import React from 'react';
import config from '../../config';
import { useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as storyActions } from '../../store/StoryStore';
import { actionCreators as logActions } from '../../store/LogStore.js';
import './SafetyInduction.css';
import Label from './Label';
import ReactPlayer from 'react-player'
import { PlayPauseBtn, ForwardBtn, BackBtn, RestartBtn } from './buttons';
import Language from './Language';
import Icon from './Icon';
import CenteredPhoto from './CenteredPhoto.js';
import ZoomPhoto from './ZoomPhoto.js';
import Wind from './Wind.js'
import Compass from './Compass.js'
import startHere from '../../images/pil_darker.png';

import klikPåIkonerne from '../../images/pil-klik-på-ikonerne.png'
import Weather from './Weather.js';
import { actionCreators as routerActions } from '../../store/RouterActions';

import questionMarkInfoPopup from '../../images/question_mark.png';



const languageTypes = [
    {
        id: 1,
        language: 'dansk',
        cultureCode: 'da-DK',
        src: '/danish_flag.jpg',
    },
    {
        id: 2,
        language: 'english',
        cultureCode: 'en-GB',
        src: '/british_flag.png',
    },
    {
        id: 3,
        language: 'german',
        cultureCode: 'de-DE',
        src: '/german_flag.jpg',
    },

];

const SafetyInduction = props => {
    const player = useRef(null);
    
    const storyState = props.storyState;
    const logActions = props.logActions;
    const storyActions = props.storyActions;
    const story = props.storyState.stories.find(story => story.id === props.storyState.currentStoryId);
    
    const infoPopups = storyState.infoPopups;

    const mapImage = story && `${config.server}/${story.imagePath}`;
    const elements = story && story.elements;
    const activeDevice = { ...props.deviceState.activeDevice }

    const setStartHereVisibility = (visibility) => {
        const startHereImage = document.getElementById('startHere');
        startHereImage.style.visibility = visibility;
    }

    const play = () => {
        setStartHereVisibility('hidden');
        toggleInfoPopup(undefined, false);
        //storyActions.resume();
        storyActions.clearChapterActions();
        storyActions.setCurrentChapter(storyState.currentChapter);

        storyActions.initNewChapter();
        player.current.seekTo(0);
        storyActions.setPlaying(true);
    }

    const stop = () => {
        setStartHereVisibility('visible');
        toggleInfoPopup(undefined, false);

        //storyActions.pause();
        storyActions.setPlaying(false);
        storyActions.clearChapterActions();
    }

    const back = () => {
        if (storyState.currentChapter > 0) {
            setStartHereVisibility('hidden');
            toggleInfoPopup(undefined, false);

            storyActions.clearChapterActions();
            storyActions.setCurrentChapter(storyState.currentChapter - 1);
            storyActions.initNewChapter();
            storyActions.setPlaying(true);
        }
    }
    const forward = () => {
        if (storyState.currentChapter < story.chapters.length - 1) {
            setStartHereVisibility('hidden');
            toggleInfoPopup(undefined, false);

            storyActions.clearChapterActions();
            storyActions.setCurrentChapter(storyState.currentChapter + 1);
            storyActions.initNewChapter();
            storyActions.setPlaying(true);
        }
    }

    const restart = () => {
        if (storyState.currentChapter > 0) {
            setStartHereVisibility('visible');
            toggleInfoPopup(undefined, false);

            storyActions.clearChapterActions();
            storyActions.setCurrentChapter(0);
            storyActions.initNewChapter();
            storyActions.setPlaying(false);
        }
    }

    const ended = () => {
        let currentChaptherIndex = props.storyState.currentChapter
        let currentChapter = story.chapters[currentChaptherIndex];

        if (isItOneOfWhoseChapters(currentChapter.name)) {
            logData(story, currentChapter, currentChaptherIndex, false, true)
        }
        
        // Navigate to new page after ended chapter
        let containsRouteData = currentChapter.onEndedChangeToRoute != null;
        let routeData = currentChapter.onEndedChangeToRoute;

        if (containsRouteData) {
            props.routerActions.navigate(routeData);
        }
        else{
            if (storyState.nextChapter === false) {
                storyActions.clearChapterActions();
            }
            else {
                forward();
            }
        }
    }

    const logData = (currentStory, currentChapter, currentCaptherIndex, isStarted, hasEnded) => {
        const deviceId = localStorage.getItem('deviceId');
        const group = activeDevice.group
        let dictionary = { 
            group: group,
            chapterId: currentChapter.id,
            language: currentStory.cultureCode,
            category: currentStory.category 
        };
        

        if (currentStory.category === 'Sikkerhedsintroduktion') {
            if (currentCaptherIndex === 0) {
                logActions.postData("chapterStarted", deviceId, dictionary)
            }
            else {
                logActions.postData("chapterEnded", deviceId, dictionary)
            }
        }
        else if (currentStory.category === 'Miljøplakat') {
            if (isStarted) {
                logActions.postData("chapterStarted", deviceId, dictionary)
            } 
            else if (hasEnded) {
                logActions.postData("chapterEnded", deviceId, dictionary)
            }
        }
    }

    const isItOneOfWhoseChapters = (currentChapterName) => {
        let importantChapterNames = [];
        if (story.category === 'Sikkerhedsintroduktion') {
            let OutroChapters = story.chapters.filter(c => c.name.toLowerCase().startsWith("outro") )
            let firstOutroChapther = OutroChapters[0]

            if (firstOutroChapther) {
                let firstOutroChaptherIndex = story.chapters.indexOf(firstOutroChapther);
                var lastChapterNameBeforeOutro = story.chapters[firstOutroChaptherIndex - 1].name
                importantChapterNames.push(lastChapterNameBeforeOutro.toLowerCase())
            }
            let firstChapter = story.chapters[0].name
            importantChapterNames.push(firstChapter.toLowerCase())
        }
        else {
            importantChapterNames = ["sorter affald", "oplag", "særlige miljøforhold" ]
        }

        let isOneOfWhoseChapters = importantChapterNames.includes(currentChapterName.toLowerCase());
        return isOneOfWhoseChapters;
    }

    const mergeElementsAndActions = (elements, elementActions) => {
        var elementsCopy = [...elements];
        const copy = elementsCopy.map((e) => {
            return {
                ...e,
                elementActions:
                    elementActions[e.id]
            }
        })
        return copy;
    };

    function executeClickAction(elementId) {

        const actions = story.actions.filter(a => a.sourceElementId === elementId && (a.actionType === 'clickToggle' || a.actionType === 'clickAndStartChapter'));

        if (actions.length > 0) {
            actions.forEach(action => {
                switch (action.actionType) {
                    case 'clickToggle':
                        // hvis destinationselementet er et zoomphoto OG source-elementet er et ikon, så skal ikon-sourceelementet have ændret sit z-index, 
                        //      så den står foran tragten til zoomphoto'et
                        // derfor kaldes toggleZoomPhotoAction, som BÅDE laver den normale toggle OG retter sourceElementets z-index
                        elementIsType(action.destinationElementId, "zoomphoto") && elementIsType(action.sourceElementId, "icon")
                            ? storyActions.toggleZoomPhotoAction(action)
                            : storyActions.toggleElementAction(action);
                        break;
                    case 'clickAndStartChapter':
                        elementIsType(action.sourceElementId, "icon")
                            ? storyActions.toggleZoomPhotoAction(action)
                            && storyActions.clearChapterActions()
                            && storyActions.setSelectedChapter(action.chapterId)
                            && storyActions.initNewChapter()
                            && storyActions.setPlaying(true)
                            : storyActions.toggleElementAction(action);
                        break;
                    default:
                }
            });

            let currentChapter = story.chapters.find(c => c.id === actions[0].chapterId);
            if (actions[0].actionType === 'clickAndStartChapter' && isItOneOfWhoseChapters(currentChapter.name)) {
                logData(story, currentChapter, null, true, false)
            }
        }
    }

    function elementIsType(elementId, type) {
        const element = elements.filter(e => e.id === elementId);

        if (element.length !== 0) {
            return element[0].type === type;
        }
        return false;
    }

    function renderElement(e, ClickAction) {
        switch (e.type.toLowerCase()) {
            case 'icon':
                return <Icon
                    key={e.id}
                    id={e.id}
                    coordinates={{ x: e.x, y: e.y }}
                    height={e.height}
                    zIndex={e.zIndex}
                    src={e.imagePath} // TODO her går det galt hvis vi bruger den lokale testdata - så skal der i stedet stå filenamr
                    visible={e.visible}
                    onClick={() => executeClickAction(e.id)}
                    {...e.elementActions}
                />;
            case 'centeredphoto':
                return <CenteredPhoto
                    key={e.id}
                    width={e.width}
                    id={e.id}
                    height={e.height}
                    src={e.imagePath}
                    visible={e.visible}
                    zIndex={e.zIndex}
                    {...e.elementActions}
                    showClose={!storyState.playing} // TODO her er der noget logik ift. showClose som vi måske skal ændre på i v2 - denne logik bliver heller ikke brugt lige nu, da den i CenteredPhoto.js er hardcodet til false
                    storyActions={storyActions}
                />;
            // disse er nu ikke hardcodet inde i CenteredPhoto komponenten
            //onClick={() => storyActions.toggleElementAction({actionType:'clickToggle', destinationElementId:e.id, property:'visible',value:'false'})}
            //onClose={() => storyActions.toggleElementAction({actionType:'clickToggle', destinationElementId:e.id, property:'visible',value:'false'})}
            case 'zoomphoto':
                return <ZoomPhoto
                    key={e.id}
                    coordinates={{ x: e.x, y: e.y }}
                    id={e.id}
                    src={e.imagePath}
                    visible={e.visible}
                    height={e.height}
                    placementElementId={e.placementElementId}
                    zIndex={e.zIndex}
                    {...e.elementActions} // indeholder de properties og værdier som skrives på action
                    storyActions={storyActions}
                />;
            case 'label':
                return <Label
                    key={e.id}
                    coordinates={{ x: e.x, y: e.y }}
                    id={e.id}
                    text={e.text}
                    height={e.height}
                    visible={e.visible}
                    zIndex={e.zIndex}
                    {...e.elementActions} // indeholder de properties og værdier som skrives på action
                    storyActions={storyActions}
                />;
            default:
                return;
        }
    }

    // Denne funktion udføres når man trykker på et af sprogene
    function changeLanguage(storyId) {

        const isCurrentStory = storyId === story.id;

        if (!isCurrentStory) {
            storyActions.changeStoryAction({ storyId: storyId });
        }
    }

    function toggleInfoPopup(group, visible) {
        storyActions.toggleInfoPopup(group, visible);
    }


    function toggleWeather(){
        storyActions.toggleWeather()
    }


    function noStoryWithChaptersAvailable() {


        return story
            ?
            <>
                <div className='static-map'>
                    <img src={story.imagePath && mapImage} alt="" />
                </div>
                {
                    mergeElementsAndActions(
                        elements,
                        storyState.currentElementActions)
                        .map((e) => {
                            return renderElement(e, executeClickAction);
                        })
                }

            </>
            :
            <>
                <div class="center">
                    <h1>
                        Der er endnu ingen interaktive historier som er udgivet
                    </h1>
                </div>
            </>
    }

    function mainContent() {

        const startHereStyling = {
            left: '9vw',
            top: '70vh',
            width: '12.9375vw',
            position: 'absolute',
            zIndex: 105,
        }

        const questionMarkInfoPopupStyling = {
            right: '1vw',
            top: '5vh',
            width: '3.5vw',
            position: 'absolute',
            zIndex: 105,
        }
        const weatherIconPopupStyling = {
            right: '1vw',
            top: '15vh',
            width: '3.5vw',
            position: 'absolute',
            zIndex: 105,
            fontSize: '3vw'

        }


        const pilKlikPåikonerne = {
            left: '14vw',
            top: '70vh',
            width: '12.9375vw',
            position: 'absolute',
            zIndex: 105,

        }



        return <>
            <Compass
                /*
                coordinates={{ x: 83.2, y: 5}}
                height={15}*/
                compassPlacement={activeDevice.compassPlacement}
                degreesToRotate={activeDevice.rotation}

            />
            <Wind
                degreesToRotate={activeDevice.rotation}
                windPlacement={activeDevice.windPlacement}
            />

            <div id="startHere" style={startHereStyling} className={props.isSafetyInduction ? '' : 'hidden'}>
                <img src={startHere} className="icon-image" alt=''></img>
            </div >

            <div id="klikPåIkonerne" style={pilKlikPåikonerne} className={props.isSafetyInduction ? 'hidden' : ''}>
                <img src={klikPåIkonerne} className="icon-image" alt=''></img>
            </div >

            <div id="questionMarkInfoPopup" style={questionMarkInfoPopupStyling} onClick={() => toggleInfoPopup()}>
                <img src={questionMarkInfoPopup} className="icon-image" alt=''></img>
            </div >
            <div id="weatherIconPopup" style={weatherIconPopupStyling} onClick={() => toggleWeather()}>
                <div className='qi-102-fill'></div>
            </div >

            {storyState.showWeatherPopup ?
                <div className='weather-popup' onClick={() => toggleWeather()}>
                    <Weather />
                </div> :
                <></>
            }

            <div className={props.isSafetyInduction ? "safety-induction-player" : "safety-induction-player hidden"}>
                <ReactPlayer
                    width={'70vw'}
                    height={'70vh'}
                    ref={player}
                    className={'video ' +
                        ((story.chapters[storyState.currentChapter].preview ||
                            story.chapters[storyState.currentChapter].path.endsWith('mp4') ||
                            story.chapters[storyState.currentChapter].path.endsWith('mov')) ? 'video-on-top' : '')}
                    controls={false}
                    playing={storyState.playing}
                    onEnded={ended}
                    onClickPreview={() => play()}
                    light={(storyState.playing || !story.chapters[storyState.currentChapter].preview)
                        ? false :
                        story.chapters[storyState.currentChapter].preview}
                    url={`${config.server}/${story.chapters[storyState.currentChapter].path}`}
                />

            </div>

            <div className='static-map'>
                <img src={mapImage} alt="" />
            </div>

            <div className={props.isSafetyInduction ? 'induction-controls' : 'induction-controls hidden'}>

                <div className='controls-inner-wrapper'>
                    <PlayPauseBtn isPlaying={storyState.playing}
                        onPlay={() => play()}
                        onStop={() => stop()} />

                    <BackBtn enabled={storyState.currentChapter > 0} onClick={back} />
                    <ForwardBtn enabled={storyState.currentChapter <= story.chapters.length - 2} onClick={forward} />

                </div>
                <div className='chapter-title'>
                    {(story.chapters[storyState.currentChapter]).name}
                </div>
                <div className='restart-inner-wrapper'>
                    <RestartBtn enabled={storyState.currentChapter > 0} onClick={restart} />
                </div>

            </div>
            {
                mergeElementsAndActions(
                    elements,
                    storyState.currentElementActions)
                    .map((e) => {
                        return renderElement(e, executeClickAction);
                    })
            }
        </>
    }
    function getRightStoriesBeforeCreatingLanguageButtons() {
        let stories = []
        if (storyState.stories[0].chapters.length > 0) {
            if (props.isSafetyInduction === true) {
                // return stories = storyState.stories.slice(0, languageTypes.length)
                storyState.stories.forEach(story => {
                    if (story.category === 'Sikkerhedsintroduktion') {
                        stories.push(story)
                    }
                })
                return stories
            }
            else {
                // return stories = storyState.stories.slice(languageTypes.length, languageTypes.length * 2)
                storyState.stories.forEach(story => {
                    if (story.category === 'Miljøplakat') {
                        stories.push(story)
                    }
                })
                return stories
            }
        }
        return storyState.stories
    }

    function createLanguageButtons() {
        let languages = [];

        getRightStoriesBeforeCreatingLanguageButtons().forEach(story => {
            let language = story.cultureCode && languageTypes.find(language => language.cultureCode === story.cultureCode);
            language && languages.push({ ...language, storyId: story.id });
        });

        return <>
            <div className={props.languageButtonIsVisible ? "outer-language-wrapper" : "outer-language-wrapper hidden"}>
                <div className="inner-language-wrapper">
                    {
                        languages.map(language =>
                            <Language
                                key={language.storyId}
                                id={language.id}
                                isCurrent={story.id === language.storyId}
                                cultureCode={language.cultureCode}
                                src={language.src}
                                onClick={() => changeLanguage(language.storyId)}
                            />
                        )
                    }
                </div>
            </div>
        </>
    }

    const infoPopupStyling = {
        position: 'absolute',
        zIndex: 1000,
        height: '80vh',
        top: '6vh',
        left: '30vw',
    }

    //function createWeather
    function createInfoPopup() {
        return <>
            {
                infoPopups.map(infoPopup => infoPopup.visible &&
                    <img key={`infoPopup-${infoPopup.group}`}
                        src={infoPopup.src}
                        onClick={() => toggleInfoPopup(infoPopup.group)}
                        style={infoPopupStyling}
                        alt='concact info'
                    ></img>
                )
            }
        </>
    }

    return (

        <>
            <div className='safety-induction'>
                {(storyState && story && story.chapters && story.chapters.length > 0 && mainContent())
                    || noStoryWithChaptersAvailable()
                }

                {createLanguageButtons()}
                {createInfoPopup()}

            </div>
        </>
    );
};

export default connect(
    state => ({
        storyState: state.storyState,
        deviceState: state.deviceState
    }),
    dispatch => ({
        storyActions: bindActionCreators(storyActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
        routerActions: bindActionCreators(routerActions, dispatch)
    })
)(SafetyInduction);
