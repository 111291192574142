import React from 'react';
import './ZoomPhoto.css';
import './Element.css';
import config from '../../config.json';


import PropTypes from 'prop-types';

const centreCoordinates = {
    x: 45 + 7.5, // 45 == så stor vw billedet er i bredden, 7.5 == afstanden fra left hen til starten af billedet
    y: 42 + 2.7, // 42 == så stor vh billedet er i højden, 2.7 == afstanden fra top ned til starten af billedet
};

const ZoomPhoto = ({ id, src, coordinates, visible, flashing, height, placementElementId, storyActions, zIndex }) => {
    const elementId = `zoomPhoto-${id}`;
    
    // Når man klikker på billedet eller på lukknappen, skal den gøre det modsatte af den action som grunden til den renders
    // vi sætter propertyen til at være visible som default, fordi hvis centeredphotoet står som alwaysvisible, så skal den lukkes i stedet for 
    let property = 'visible';
    if(flashing){
        property = 'flashing';
    }

    const onClick= () => storyActions.toggleZoomPhotoAction({actionType:'clickToggle' || 'clickAndStartChapter', destinationElementId:id, sourceElementId: placementElementId, property:property, value:'false'});
    const cls = flashing ? 'blink ' : '';

    // brug coordinates til at finde ud af hvor det statiske billede skal pege hen
    const y = coordinates.y;
    const x = coordinates.x;

    height = height || 35;
    const wrapperStyling = {
        left: x + 'vw',
        top: y - height / 2 + 'vh',
        height: height + 'vh',
        width: height + 'vw',
        zIndex: zIndex || 30
    };

    const imageStyling = {
        height: height + 'vh',
        width: height + 'vh'
    }

    const xSmallest =  centreCoordinates.x - 5;
    const xLargest = centreCoordinates.x + 5;
    const ySmallest = centreCoordinates.y - 25;
    const yLargest =  centreCoordinates.y + 25;

    /* Bruges til test
    const firkantStyling = {
        top: ySmallest + 'vh',
        left: xSmallest +'vw',
        width: xLargest - xSmallest + 'vw',
        height: yLargest - ySmallest + 'vh'
    }*/

    const ratio = (9 / 16);
    let ang = 0;

    // hvis det er indenfor firkanten som står rundt om midten
    if((x > xSmallest && x < xLargest) && (y > ySmallest && y < yLargest)){

        // hvis den står i øvre halvdel af firkanten 
        if(y < centreCoordinates.y - 10){
            // vis nede til højre
            ang = ang + 45;
            if(x > centreCoordinates.x){ // hvis den står til højre, skal den pege ned til venstre
                ang = ang + 90;
            }
        }
         // hvis den står i nedre halvdel af firkanten 
        else if(y > centreCoordinates.y + 10){
            // vis oppe til højre
            ang = ang - 45;
            // hvis den står til højre, skal den pege ned til venstre
            if(x > centreCoordinates.x){ 
                ang = ang - 90;
            }
        }  // hvis den står i højre halvdel
        else if(x > centreCoordinates.x){
            // vis til venstre
            ang = 180;
        }
    }
    else { // skal den bare placeres normalt ved at udregne vinklen
          // point 1 (x1, y1) or origine of vertex
        // point 2 (x2, y2)
        const dx = centreCoordinates.x - x
        const dy = centreCoordinates.y * ratio - y * ratio
        ang = Math.atan2(dy, dx) * 180 / Math.PI;
    }

    wrapperStyling.transform = `rotate(${ang}deg)`;
    imageStyling.transform = `rotate(${-ang}deg)`;

    function renderPhoto() {
        return (
            <div id={elementId} onClick={onClick}>
                {/*<div class="firkant" style={firkantStyling}></div>
                <div class="punkt" style={centreStyling}></div>*/}
                < div className={`zoom-wrapper rotate-around-point ${cls}`} style={wrapperStyling}>
                    <img alt="zoomkegle" src={'/zoomeffect2.png'} className={'pointer'}></img>
                    <img alt="zoombillede" src={`${config.server}${src}`} className={'zoom-image'} style={imageStyling}></img>
                </div >

            </div>
            
        )
    };

    if (visible || flashing) { return renderPhoto() } else return <></>
};



ZoomPhoto.propTypes = {
    id: PropTypes.number,
    src: PropTypes.string,
    coordinates: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
    }),
    size: PropTypes.number,
    visible: PropTypes.bool,
}
export default ZoomPhoto;