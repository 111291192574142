import React from 'react';
import { actionCreators as pauseActions } from '../store/PauseStore';
import { actionCreators as routerActions } from '../store/RouterActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactPlayer from 'react-player';
import config from '../config';
import './PauseScreen.css';


const PauseScreen = (props) => {
    const pauseState = props.pauseState;
    const pauseActions = props.pauseActions;

    var videos = pauseState.pauseVideos
    
    const nextVideo = () => {
        pauseActions.nextVideo()
    }

    var showStaticPauseVideo = pauseState.currentVideoIndex >= videos.length;

    var path = (showStaticPauseVideo ? '/Pausebillede.mp4' : (config.server + videos[pauseState.currentVideoIndex].path))

    return <>
        <div className='video-container' onClick={() => props.routerActions.navigate('/')}>
            {
                videos.length !== 0 ?
                <div>
                    <div className={showStaticPauseVideo ? 'hidden text-box' : 'text-box'}>
                        Tryk på skærmen for at starte den lokale <br/> sikkerheds- og miljøinstroduktion
                    </div>
                    <ReactPlayer
                        className='video-player'
                        url = {path}
                        controls = {false}
                        playing = {true}
                        muted={true}
                        width = "100vw"
                        height = "100vh"
                        onEnded={nextVideo}
                    />
                </div> :
                <div className='no-video-player' onClick={() => props.routerActions.navigate('/')} style={{width: '100vw', height: '100vh'}}>
                    Ingen videoer tilgængelig
                </div>
            }
        </div>
    </> 
};

export default connect(
    state => ({
        pauseState: state.pauseState
    }),
   dispatch => ({
        pauseActions: bindActionCreators(pauseActions, dispatch),
        routerActions: bindActionCreators(routerActions, dispatch),
    })
)(PauseScreen);