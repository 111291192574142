import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as documentActions } from '../store/DocumentStore';
import config from '../config';
import Menu from './Menu';
import './Documents.css';
import folderIcon from '../images/documents.png';

const Documents = props => {

    const documents = props.documentStore.documents;
    const activeDocument = props.documentStore.activeDocument;

    const sortedDocs = documents && documents.map(x => { return { ...x, category: x.category || '' } }).sort((a, b) => a.category > b.category ? 1 : a.category < b.category ? -1 : 0);
    const oldCategories = sortedDocs && sortedDocs.map(item => item.category).filter((item, i, ar) => ar.indexOf(item) === i);

    const [activeCat, setActiveCat] = useState((oldCategories && oldCategories[0]) || '');

    const categories = convertToFolderStructure(oldCategories);

    useEffect(() => {
        //const filtered = sortedDocs && sortedDocs.filter(x => x.category === activeCat);
        const filtered = sortedDocs && sortedDocs.filter(x => x.category.toUpperCase() === activeCat);
        if (filtered && filtered.length > 0) {
            props.documentActions.setActive(filtered[0]);
        }
        //eslint-disable-next-line
    }, [activeCat]);

    return (
        <div className="App">
            <Menu />
            <div className='documents'>
                <div className='documents-container'>
                    {categories && categories.map(c =>
                        renderCategory(c, activeCat, setActiveCat, sortedDocs, activeDocument, props)
                    )}
                </div>
                {activeDocument &&
                    <div className='preview'>
                        <embed key={`${config.server}${activeDocument.path}`} src={`${config.server}${activeDocument.path}`} alt="pdf" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html" />
                    </div>
                }
            </div>
        </div>
    );
};


function renderCategory(category, activeCat, setActiveCat, sortedDocs, activeDocument, props, path, depth = 0) {
    // Opret en fuldstændig sti (fullPath) ved at kombinere en eksisterende sti (hvis den findes) med navnet på en kategori.
    // Hvis 'path' eksisterer, bliver fullPath sammensat som 'path|kategoriensNavn', hvor '|' fungerer som en separator.
    // Hvis 'path' ikke eksisterer, bliver fullPath bare lig med kategoriensNavn.
    const fullPath = path ? `${path}|${category.name}` : category.name;
    const normalizedPath = fullPath.replace(/\s+/g, ''); // Remove spaces for comparison
    const indentClass = `category-item depth-${depth}`;



    // Function to handle the click event and toggle the expanded/collapsed state
    const toggleCategory = () => {
        setActiveCat(normalizedPath === activeCat ? '' : normalizedPath);
    };



    // Function to check if a document's category matches the current category
    /*const isMatchingCategory = (documentCategory) => {
        return documentCategory.replace(/\s+/g, '') === normalizedPath;
    };*/



    // Function to check if a document's category matches the current category
    const isMatchingCategory = (documentCategory) => {
        return documentCategory.toUpperCase().replace(/\s+/g, '') === normalizedPath;
    };


    return (
        <div key={category.name} className={indentClass}>
            <h2 className={normalizedPath === activeCat ? 'expanded' : ''} onClick={toggleCategory}>
                <img src={folderIcon} alt={'folder'} />
                {`${category.name || 'Ingen kategori'}`}
                <span className={'expand'}>{normalizedPath === activeCat ? '-' : '+'}</span>
            </h2>
            {normalizedPath === activeCat && sortedDocs && sortedDocs.filter(x => isMatchingCategory(x.category)).map((d) => {
                return (
                    <div key={d.id} onClick={() => props.documentActions.setActive(d)} className={`document ${activeDocument && d.id === activeDocument.id ? 'active' : ''}`}>
                        {d.name}
                    </div>
                );
            })}
            {category.subcategories && category.subcategories.map(subCat => renderCategory(subCat, activeCat, setActiveCat, sortedDocs, activeDocument, props, fullPath, depth + 1))}
        </div>
    );
}



//Konverterer en liste af kategorier til en struktur af mapper og undermapper.
// categories En liste af kategorinavne i form af stier adskilt af '|'
function convertToFolderStructure(categories) {

    // En map, der vil indeholde den hierarkiske struktur af kategorier.
    const categoryMap = {};

    // Itererer gennem hver kategori og opdaterer categoryMap med hierarkiet.
    for (const category of categories) {
        //const categoryParts = category.split('|').map(part => part.trim());
        const categoryParts = category.split('|').map(part => part.trim().toUpperCase()); // Deler kategorinavnet ved '|' og trimmer og konverterer hver del til store bogstaver.
        let currentCategoryMap = categoryMap;

        // Itererer gennem hver del af kategorinavnet og opretter manglende undermapper.
        for (const part of categoryParts) {
            if (!currentCategoryMap[part]) {
                currentCategoryMap[part] = {};
            }
            currentCategoryMap = currentCategoryMap[part];
        }
    }

  
    function convertMapToStructure(map) {
        const folderStructure = [];

        // Itererer gennem hver nøgle i map og opretter undermapper som objekter.
        for (const key in map) {
            const subfolder = {
                name: key,
                subcategories: convertMapToStructure(map[key]),
            };
            folderStructure.push(subfolder);
        }

        return folderStructure;
    }

    return convertMapToStructure(categoryMap);
}




export default connect(
    state => ({
        documentStore: state.documentStore,
    }),
    dispatch => ({
        documentActions: bindActionCreators(documentActions, dispatch)
    })
)(Documents);