import React from 'react';
import { actionCreators as instructionActions } from '../../store/InstructionStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './InstructionItem.css';

export const InstructionItem = (props) => {
    return <>
        <div>
            <video className='video-container' preload="metadata"> 
                <source src={props.path + '#t=2'} type={props.type}></source>
            </video>
            <div className='video-description'>
                {props.name}
            </div>
        </div>
    </>
}

export default connect(
    state => ({
        instructionState: state.instructionState
    }),
   dispatch => ({
        instructionActions: bindActionCreators(instructionActions, dispatch)
    })
)(InstructionItem);