import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as weatherActions } from '../../store/WeatherStore';
import { WeatherItem } from './WeatherItem';
import './Weather.css';

const daysOfWeek = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'];

const Weather = (props) => {
  const weatherState = props.weatherState;
  const tempsList = weatherState.temps;
  if(tempsList.length < 1){
    return <></>;
  } 
  const groupedTemps = tempsList.reduce((result, tempData) => {
    const date = new Date(tempData.dateTime);
    //  const dayOfWeek = daysOfWeek[date.getDay()];
    const time = date.getHours();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const dateString = date.toLocaleDateString('da-DK', options);

    if (!result[dateString]) {
      result[dateString] = {
        '6-11': { temps: [], windSpeed: [], nedbor: [], cloudcover: [] },
        '11-16': { temps: [], windSpeed: [], nedbor: [], cloudcover: [] },
        '16-22': { temps: [], windSpeed: [], nedbor: [], cloudcover: [] },
      };
    }

    if (time >= 6 && time < 11) {
      result[dateString]['6-11'].temps.push(tempData.temp);
      result[dateString]['6-11'].windSpeed.push(tempData.wind);
      result[dateString]['6-11'].nedbor.push(tempData.nedbor);
      result[dateString]['6-11'].cloudcover.push(tempData.cloudcover);
    } else if (time >= 11 && time < 16) {
      result[dateString]['11-16'].temps.push(tempData.temp);
      result[dateString]['11-16'].windSpeed.push(tempData.wind);
      result[dateString]['11-16'].nedbor.push(tempData.nedbor);
      result[dateString]['11-16'].cloudcover.push(tempData.cloudcover);
    } else if (time >= 16 && time <= 22) {
      result[dateString]['16-22'].temps.push(tempData.temp);
      result[dateString]['16-22'].windSpeed.push(tempData.wind);
      result[dateString]['16-22'].nedbor.push(tempData.nedbor);
      result[dateString]['16-22'].cloudcover.push(tempData.cloudcover);
    }

    return result;
  }, {});

  const calculateAverage = (data, type) => {
    if (!data || data.length === 0 || data.length <= 3) return 'No data available';
    const sum = data.reduce((acc, value) => acc + value, 0);
    const average = sum / data.length;
    return type === 'temps' ? average : type === 'windSpeed' ? average : type === 'cloudcover' ? average : 'No data available';
  };

  const calculateNedborSum = (nedbørData) => {
    var sum = 0;

    if (!nedbørData || nedbørData.length <= 3) {
      return 'No data available';
    }

    for (let i = 1; i < nedbørData.length; i++) {
      const hourlyNedbor = nedbørData[i] - nedbørData[i - 1];
      sum = sum + hourlyNedbor;
    }
    return sum; 
  };

  return (
    <div className="Weather">
      <div className="weather-container">
        <table className="temperature">
          <tbody>
            <tr>
              <th></th>
              <th>
                <h3>Morgen</h3>
              </th>
              <th>
                <h3>Middag</h3>
              </th>
              <th>
                <h3>Aften</h3>
              </th>
            </tr>
            {Object.keys(groupedTemps).map((date, index) => (
              <tr className='value-row' key={index}>
                <td className="dateMargin">
                  {daysOfWeek[new Date(date).getDay()]}
                </td>
                <td className='weatherItem-container'>
                  <WeatherItem
                    Temperature={calculateAverage(groupedTemps[date]['6-11'].temps, 'temps')}
                    WindSpeed={calculateAverage(groupedTemps[date]['6-11'].windSpeed, 'windSpeed')}
                    Precipitation={calculateNedborSum(groupedTemps[date]['6-11'].nedbor)}
                    CloudCover={calculateAverage(groupedTemps[date]['6-11'].cloudcover, 'cloudcover')}
                  />
                </td>
                <td className='weatherItem-container'>
                  <WeatherItem
                    Temperature={calculateAverage(groupedTemps[date]['11-16'].temps, 'temps')}
                    WindSpeed={calculateAverage(groupedTemps[date]['11-16'].windSpeed, 'windSpeed')}
                    Precipitation={calculateNedborSum(groupedTemps[date]['11-16'].nedbor)}
                    CloudCover={calculateAverage(groupedTemps[date]['11-16'].cloudcover, 'cloudcover')}
                  />
                </td>
                <td className='weatherItem-container'>
                  <WeatherItem
                    Temperature={calculateAverage(groupedTemps[date]['16-22'].temps, 'temps')}
                    WindSpeed={calculateAverage(groupedTemps[date]['16-22'].windSpeed, 'windSpeed')}
                    Precipitation={calculateNedborSum(groupedTemps[date]['16-22'].nedbor)}
                    CloudCover={calculateAverage(groupedTemps[date]['16-22'].cloudcover, 'cloudcover')}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2 className='vejrDataFraDmi'>Vejrdata fra DMI</h2>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    weatherState: state.weatherStore,
  }),
  (dispatch) => ({
    weatherActions: bindActionCreators(weatherActions, dispatch),
  })
)(Weather);
