import axios from "axios";
import config from '../config';
import {createAuth} from "../Helpers";

export const reducerName = 'instructionState'

const INSTRUCTIONVIDEO_GETALL_RESPONSE = 'INSTRUCTIONVIDEO_GETALL_RESPONSE';
const PLAY = 'PLAY';
const STOP_PLAY = 'STOP_PLAY';

const initialState = {
    instructionVideos: [],
    currentVideo: null
}; 

export const actionCreators = {
    getAll: () => async(dispatch, getState) => {
        axios({
            method: 'GET',
            url: `${config.server}/api/audiovideos?group=${getState().deviceState.activeDevice.group}&tag=Instruction`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({type: INSTRUCTIONVIDEO_GETALL_RESPONSE, data: response.data});
        }).catch(error =>{
            console.log('ERROR FETCHING INSTRUCTIONVIDEOS', error);
        });
    },
    play: (index) => async(dispatch, getState) => {
        dispatch({ type: PLAY, index });
    },
    stopPlay: () => async(dispatch, getState) => {
        if(getState().instructionState.currentVideo !== null){
            dispatch({ type: STOP_PLAY });
        }
    }
}

const reducerMethods = {
    INSTRUCTIONVIDEO_GETALL_RESPONSE: (state, action) => {
        if (action.data.lengh >= state.currentVideo) {
            return{
                ...state,
                instructionVideos: action.data,
                currentVideo: null
            }
        }
        return{
            ...state,
            instructionVideos: action.data
        }
    },
    PLAY: (state, action) => {
        return{
            ...state,
            currentVideo: action.index
        }
    },
    STOP_PLAY: (state, action) => {
        return{
            ...state,
            currentVideo: null
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) {
        return method(state, action);
    }
    return state;
};