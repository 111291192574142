import {actionCreators as documentActions} from './DocumentStore';
import {actionCreators as pauseActions} from './PauseStore';
import {actionCreators as windActions} from "./WindStore";
import {actionCreators as storyActions} from "./StoryStore";
import {actionCreators as deviceActions} from './DeviceStore';
import {actionCreators as weatherActions} from './WeatherStore';
import {actionCreators as instructionActions} from './InstructionStore';

export const reducerName = 'appStore';

const APP_REFRESH_EVERYTHING = 'APP_REFRESH_EVERYTHING';


export const actionCreators = {
    refresh: (currentStoryId) => async(dispatch) => {
        dispatch({type: APP_REFRESH_EVERYTHING});
        dispatch(deviceActions.getAll());
        dispatch(documentActions.getAll());
        dispatch(pauseActions.getAll());
        dispatch(instructionActions.getAll());
        dispatch(windActions.getWind());
        dispatch(storyActions.getStoriesAndElements(currentStoryId));
    },
    refreshSlow: () => async(dispatch) => {

    },
    refreshHourly: () => async(dispatch) => {
        dispatch(weatherActions.getAll());
    }
};
