import React, { Component } from 'react';
import './css/App.css';
import './css/OrstedSans.css';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { actionCreators as routerActions } from './store/RouterActions';
import { actionCreators as windActions } from './store/WindStore';
import Menu from './components/Menu';
import SafetyInduction from './components/safetyinduction/SafetyInduction';
import {actionCreators as deviceActions} from './store/DeviceStore';
import {actionCreators as storyActions} from './store/StoryStore';

class Home extends Component {
    constructor(props){
        super(props);

        if (this.defaultStoryId() !== undefined) {
            let storyId = this.defaultStoryId()
            this.changeStory(storyId)
        }
    }
    
    storyActions = this.props.storyActions;
    storyState = this.props.storyState;

    changeStory(id){
        this.storyActions.setPlaying(false);
        this.storyActions.clearChapterActions();
        this.storyActions.toggleInfoPopup(undefined, false);
        this.storyActions.changeStoryAction({storyId:id})
    }
    defaultStoryId(){
        if (this.storyState.stories[0].chapters.length !== 0) {
            return this.storyState.stories.find(s => s.cultureCode === 'da-DK' && s.category === 'Sikkerhedsintroduktion').id
        }
        return undefined
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        clearInterval(this.updateDates);
    }

    render() {
        return (
            <div className="App">
                <Menu />
                <SafetyInduction 
                    isSafetyInduction={true}
                    languageButtonIsVisible={true}
                />
            </div>
        );
    }
}

export default connect(
    state => ({ 
        storyState: state.storyState,
    }),
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch),
        windActions: bindActionCreators(windActions, dispatch),
        deviceActions: bindActionCreators(deviceActions, dispatch),
        storyActions: bindActionCreators(storyActions, dispatch)
    })
)(Home);