import React from 'react';
import { actionCreators as instructionActions } from '../../store/InstructionStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactPlayer from 'react-player';

const InstructionPlayer = (props) => {
    return <>
        <div className='video-player'>
            <ReactPlayer
                url = {props.path}
                controls = {true}
                playing = {true}
                muted={false}
                width='100%'
                height='100%'
            />
        </div>
    </> 
};

export default connect(
    state => ({
        instructionState: state.instructionState
    }),
   dispatch => ({
        instructionActions: bindActionCreators(instructionActions, dispatch),
    })
)(InstructionPlayer);