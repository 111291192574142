import axios from "axios";
import config from '../config';
import {createAuth} from "../Helpers";

export const reducerName = 'logState'

const POST_DATA = 'POST_DATA';

const initialState = {
}; 

export const actionCreators = {
    postData: (operation, deviceId, logData) => async(dispatch, getState) => {
        axios({
            method: 'POST',
            url: `${config.server}/api/logs?operation=${operation}&deviceId=${deviceId}`,
            data: logData,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({type: POST_DATA, data: response.data});
        }).catch(error =>{
            console.log('ERROR PUSHING LOGDATA', error);
        });
    },
}

const reducerMethods = {
    POST_DATA: (state, action) => {
        return{
            ...state
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) {
        return method(state, action);
    }
    return state;
};