import React from 'react';
import './CenteredPhoto.css';
import './Element.css';
import config from '../../config.json';


import PropTypes from 'prop-types';

const CenteredPhoto = ({ id, src, height, visible, flashing, zIndex, showClose, storyActions}) => {

    // TODO lige nu er den altid hardcodet til at aldirg at vise lukfunktionen - det kommer med i version 2
    showClose = false;
    
    // Når man klikker på billedet eller på lukknappen, skal den gøre det modsatte af den action som grunden til den renders
    // vi sætter propertyen til at være visible som default, fordi hvis centeredphotoet står som alwaysvisible, så skal den lukkes i stedet for 
    let property = 'visible';
    if(flashing){
        property = 'flashing';
    }

    const onClick= () => storyActions.toggleElementAction({actionType:'clickToggle' || 'clickAndStartChapter', destinationElementId:id, property:property, value:'false'});
    const onClose= () => storyActions.toggleElementAction({actionType:'clickToggle' || 'clickAndStartChapter', destinationElementId:id, property:property, value:'false'});

    const elementId = `centeredPhoto${id}`;
    const cls = flashing ? 'blink ' : '';
    const appear = visible ? 'appear' : '';


    const mapHeightAndWidth = 84;// respektivt i vh og vw
    const distanceToTop = 2.7; // i vh
    const distanceToLeft = 10.5; // i vw

    const xMiddle = (mapHeightAndWidth / 2) + distanceToLeft;
    const yMiddle = (mapHeightAndWidth / 2) + distanceToTop;

    const styling = {
        width: height && height > 0 ? `${height}vw` : `45vw`,
        left: `${xMiddle}vw`,
        top: `${yMiddle}vh`,
        zIndex: zIndex || 30
    }

    function renderPhoto() {
        return (
            < div id={elementId} className={`centered-photo-wrapper ${appear} ${cls}`} style={styling} >
                {showClose ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16" onClick={onClose}>
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                    </svg>
                ) : (<></>)
                }
                <img src={`${config.server}${src}`} className="centered-photo-image" alt='' onClick={onClick} ></img>
            </div >
        )
    };

    if (visible || flashing) { return renderPhoto() } else return <></>
};

CenteredPhoto.propTypes = {
    id: PropTypes.number,
    src: PropTypes.string,
    visible: PropTypes.bool,
    enabled: PropTypes.bool,
    flashing: PropTypes.bool,
    onClick: PropTypes.func
}
export default CenteredPhoto;